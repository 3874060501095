<template>
  <div class="tw-flex tw-justify-center">
    <div class="page-sculptor">
      <div>
        <v-btn class="add-pd" @click="$router.push({name:'AllUnits'})">
          <icons name="back" class="mr-2" />
          All Units</v-btn>
      </div>
      <div class="white-bg">
        <validation-provider name="unit name" rules="required" v-slot="{ classes, errors }">
          <h3 class="text-headings ">Unit Name</h3>
          <v-text-field type="text" class="text-fields mt-3" placeholder="Design" outlined hide-details
            v-model="UnitData.name" dense />
          <span class="error-text">{{errors[0]}}</span>
        </validation-provider>
      </div>
      <div class="white-bg mt-7">
        <validation-provider name="unit description" rules="required" v-slot="{ classes, errors }">
          <h3 class="text-headings ">Unit Description</h3>
          <v-textarea type="text" class="text-fields mt-3" placeholder="Design Unit deals with everything design" outlined hide-details
                        v-model="UnitData.description" dense />
          <span class="error-text">{{errors[0]}}</span>
        </validation-provider>
      </div>
      <div class="white-bg mt-7">
        <div class="mb-7">
        <label class="text-headings">Departments</label>
        <v-text-field color="#004aad" class="mt-3" type="text" hide-details placeholder="Enter a new department and press Enter or click the plus icon"
          outlined dense v-model="newTag" @keydown.enter.prevent="addTag">
          <template v-slot:append-outer>
            <v-icon color="#004aad" @click="addTag" class="ml-2">mdi-plus-circle-outline</v-icon>
          </template>
        </v-text-field>
        <span class="error-text mb-3" v-if="tagError">{{tagError}}</span>
      </div>
        <div class="tag-list">
          <div v-for="(tag, index) in UnitData.departments" :key="index" class="tag mb-3">
            {{ tag }}
            <v-icon @click.stop="removeTag(index)" color="#004aad" class="ml-3">mdi-close-circle-outline</v-icon>
          </div>
        </div>
      </div>
      <div class="buttons">
                    <v-btn text class="ctrl-btn back" :loading="loading2" @click="clearAndSaveData">Add another</v-btn>
                    <v-btn text class="ctrl-btn next" @click="saveData" :loading="loading1">
                         Done
                    </v-btn>
                </div>
    </div>
  </div>
</template>

<script>
  import Icons from '../../../components/reuseables/Icons.vue'
  import {addNewUnit} from "@/services/api/APIService";
  export default {
    components: {
      Icons
    },
    name: "AddUnit",
    data() {
      return {
        UnitData: {
          name: "",
          departments: [],
        },
        newTag: "",
        tagError: "",
        loading1:false,
        loading2:false
      }
    },
    methods: {
      addTag() {
        const trimmedTag = this.newTag.trim();
        if (trimmedTag === '') {
          this.setAndClearError("Please enter a department.")
          return;
        }
        if (this.UnitData.departments.includes(trimmedTag)) {
          this.setAndClearError("This tag has already been added.")
          return;
        }
        this.UnitData.departments.push(trimmedTag);
        this.newTag = '',
        this.tagError = ''
      },
      removeTag(index) {
        this.UnitData.departments.splice(index, 1)
      },
      setAndClearError(erroMsg){
        this.tagError = erroMsg;
        setTimeout(()=>
        {
          this.tagError='';
        }, 4000)
      },
      saveData(){
        this.loading1=true
        let data = {}
        data.name = this.UnitData.name
        data.description = this.UnitData.description
        data.departments = []
        this.UnitData.departments.forEach(department => data.departments.push({name: department}))
        addNewUnit(data).then(()=>{
          this.loading1 = false
          this.UnitData={
            name:'',
            departments:[]
          };
          this.$refs.observer.reset();
        }).finally(()=> this.loading1 = false)
      },
      clearAndSaveData(){
        this.loading2 =true
        this.UnitData={
          name:'',
          departments:[]
        };
        this.$refs.observer.reset();
      }
    },

  }
</script>

<style lang="scss" scoped>
  .page-sculptor {
    display: flex;
    flex-direction: column;
    width: 700px;

    @media (max-width:768px) {
      width: 100%;
    }
  }

  .text-headings {
    color: #4F4F4F;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .v-text-field input {
    color: #C4C4C4;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
  }

  .tag-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 10px;
    background-color: #fff;
  }

  .tag {
    border-radius: 5px;
    background: rgba(119, 202, 241, 0.18);
    color: #4F4F4F;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 7px 12px;
    text-transform: capitalize;
  }

  .remove-tag {
    cursor: pointer;
    margin-left: 8px;
    font-weight: bold;
  }

  .add-pd {
    color: #263238;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
    letter-spacing: 0;
    margin-bottom: 15px;
    @media (max-width:768px) {
      justify-content: start;
      margin-top: 13px;
      font-size: 17px;
    }
  }

  .white-bg {
    background-color: #fff;
    padding: 30px;
    height: fit-content;
    border-radius: 10px;

    @media (max-width:768px) {
      padding: 15px;
    }
  }
  .ctrl-btn {
        width: 110px;
        height: 30px;
        border-radius: 10px;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize !important;
        letter-spacing: 0px;
        @media (max-width:400px) {
          width: 100%;
        }
    }
    .buttons {
        margin-top: 50px;
        display: flex;
        justify-content: flex-end;
        @media (max-width:768px) {
          margin-top: 30px;
        }
        @media (max-width:400px) {
          flex-direction: column;
        }
    }
    ::v-deep .v-btn.next {
        color: #ffff;
        background-color: #004AAD !important;
        margin-left: 20px;
        @media (max-width:400px) {
          margin-left: 0;
          margin-top: 20px;
        }
    }

    ::v-deep .v-btn.back {
        color: #004AAD;
        background-color: #ffff;
        border: 2px solid #004AAD;
    }

</style>